import { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import FirebaseService from '../../services/FirestoreService';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link
} from '@material-ui/core';
// import getInitials from 'src/utils/getInitials';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


const CustomerListResults = ({ documents,filterEmail, ...rest }) => {
  const navigate = useNavigate();

  const [listaDocumetosFull, setListaDocumetosFull] = useState([]);
  const [listaDocumetos, setListaDocumetos] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);

  useEffect(async () => {
 
    
    let aData = [] 
    for (let doc of documents) {
      let obj = {}
      obj.Data = doc.Data;
      obj.Pagamento = doc.Pagamento;
      obj.Obs = doc.Obs;
      obj.Documento = doc.Documento || false;
      obj.Email = doc.Usuario;
      //console.log(doc);
      aData.push(obj)
    }
    setListaDocumetos(aData);
    setListaDocumetosFull(aData);
    setIsLoadingList(true);
    
  }, []);
  
  
  useEffect(async () => {
    if (filterEmail !== false) {
      console.log('filter email:', filterEmail);
      if (filterEmail) {
        const docs = listaDocumetos.filter(({ Email }) => Email == filterEmail );
        setListaDocumetos(docs);
      } else {
        setListaDocumetos(listaDocumetosFull);
      }
    }
    
    
  }, [filterEmail]);
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Data
                </TableCell>
                <TableCell>
                  Pagamento
                </TableCell>
                <TableCell>
                  OBS 
                </TableCell>

              </TableRow>
            </TableHead>
            
            <TableBody>
             {listaDocumetos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((document) => (
  
                <TableRow
                  hover
                  key={document.id}
                  selected={false}
                >
                  <TableCell>
                    {document.Email}
                  </TableCell>
                  <TableCell>
                    {document.Data}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {document.Pagamento}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {document.Obs}
                  </TableCell>
                  
                  <TableCell>
                    
                  </TableCell>
                  <TableCell align="center">
                   { document.Documento && (<Link href={document.Documento} target='blank'>Baixar</Link>)} 
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerListResults;
