import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon
} from '@material-ui/core';
import FirebaseService from '../../services/FirestoreService';
import { useNavigate } from 'react-router-dom';

const CustomerListToolbar = (props) => {
  const [listaEmpresas, setListaEmpresas] = useState({ });
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [emailSelect, setEmailSelect] = useState(0);
  const navigate = useNavigate();
  
  const handleChange = event => {
    setEmailSelect(event.target.value)
    console.log(event.target.value);
    props.fn(event.target.value)
  };
  
  
  
  useEffect(async () => {
    
      const empresas = await FirebaseService.listaEmpresas();
      
       setListaEmpresas(empresas)
   
       let aData = [{ 'value': '', label: 'Remover filtro' }];
       let cont = 1;
       for (let empresa of empresas) {
          let obj = {}
          obj.value = empresa.email;
          obj.label = empresa.email;
          
          aData.push(obj)
        }
        setListaEmpresas(aData);
        setIsLoadingList(true);
  }, []);
  
  
 

  return (
    <>
      <Box {...props}>
        {/*<Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleNewTerms}
          >
            Adicionar cliente
          </Button>
        </Box>*/}
        <Box sx={{ mt: 3 }}>
          <Card>
            <CardContent>
              {isLoadingList && (<Box sx={{ maxWidth: 500 }}>
                <TextField
                  fullWidth
                  label=""
                  name="filtroEmail"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={emailSelect}
                  variant="outlined"
                  margin="normal"
                >
                  {listaEmpresas.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>)}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default CustomerListToolbar;
