import { useState, useRef,useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import EmpresaDetalhes from 'src/components/empresa/EmpresaDetalhes';

import CnpjDetalhes from 'src/components/consultas/CnpjDetalhes';
import Title from 'src/components/Title';
import { useParams, useNavigate } from 'react-router-dom';
import FirebaseService from '../services/FirestoreService';

const Empresa = () => {
  const [doResulte, setDoResulte] = useState(false);
  const [response, setResponse] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  
  useEffect(async () => {
    
    const user = await localStorage.getItem('@valecred/user');
    if (!JSON.parse(user).admin) {
      navigate('/404', { replace: true });
    }
    
    
    const empresa = await FirebaseService.getEmpresaByID(params.empresa);
    setDoResulte(true)
    setResponse(empresa)
  }, []);
  

  return (
    <>
      <Title />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ pt: 1 }}>
           {doResulte &&  (<EmpresaDetalhes data={response} />)}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Empresa;
