import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import FirebaseService from '../services/FirestoreService';
import AlertDialog from '../components/AlertDialog'
import { v4 as uuid } from 'uuid';
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Divider,
  TextField,
  Typography,
  Alert
} from '@material-ui/core';

const Register = () => {
  const navigate = useNavigate();
  const [emiteBoletos, setEmiteBoletos] = useState(0);
  const [mostraCampoBoletos, setMostraCampoBoletos] = useState(false);

  const [temSistema, setTemSistema] = useState(0);
  const [mostraCampoSistema, setMostraCampoSistema] = useState(false);
  
  const [alertVisivel, setAlertVisivel] = useState(false);
  const [alertCadastro, setAlertCadastro] = useState(false);

  const regimes = [
    {
      value: '1',
      label: 'Simples nacional'
    },
    {
      value: '2',
      label: 'MEI'
    },
    {
      value: '3',
      label: 'Lucro presumido'
    },
    {
      value: '4',
      label: 'Lucro real'
    },
  ];

  const selSimNao = [
    {
      value: '1',
      label: 'Sim'
    },
    {
      value: '0',
      label: 'Não'
    },
    
  ];

  //React.useEffect(async () => {
   
   
  //}, []);
  
  const handleChange2 = (event)=> {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
        console.log(event.target.value)
        // this.setState({number: event.target.value})
      }
  }

  const selectEmiteBoleto = event => {
      setEmiteBoletos(event.target.value)
      if(event.target.value==1){
        setMostraCampoBoletos(true)
      }else{
        setMostraCampoBoletos(false)
      }
  };

  const selectTemSistema = event => {
      setTemSistema(event.target.value)
      if(event.target.value==1){
        setMostraCampoSistema(true)
      }else{
        setMostraCampoSistema(false)
      }
  };
  
  const gravaCadastro = async (values,resetForm) => {
    const empresaFind = await FirebaseService.getEmpresaByCnpj(values.cnpj);
    console.log('localizado?', empresaFind);
    if (empresaFind) {
      resetForm();
      setAlertCadastro(true);
      return false;
    }
    
    values.createdAt = moment().format();
    values.id = uuid();
    console.log(values);
    const response = await FirebaseService.addEmpresa(values);
    if (response !== false) {
      resetForm();
      console.log(response);
      setAlertVisivel(true)
    }
    
    
    
  }

  return (
    <>
      <Helmet>
        <title>Finup | Cadastro</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '1100',
          justifyContent: 'center',
         
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              razaoSocial: '',
              cnpj: '',
              inscricaoMunicipal: '',
              inscricaoEstatual: '',
              logradouro: '',
              cep:'',
              bairro:'',
              cidade:'',
              estado:'',
              regime:1,
              qtBancos:'',
              qtContas: '',
              boletos: 0,
              qtBoletos: 0,
              protesto: 0,
              processoVenda: '',
              qtNotas: '',
              qtContratos: '',
              temSistema: 0,
              sistema: '',
              senha: '',
              email: '',
              celular: '',
              cpf: '',
              nascimento: ''
            }}
            validationSchema={
              Yup.object().shape({
                razaoSocial: Yup.string().max(255).required('Por favor, informe a razão social'),
                cnpj: Yup.string().max(16).required('Por favor, inseria o CNPJ'),
                inscricaoMunicipal: Yup.string().max(255).required('Por favor, insira a Inscrição municipal'),
                inscricaoEstatual: Yup.string().max(255).required('Por favor, insira a Inscrição estadual'),
                logradouro: Yup.string().max(255).required('Por favor, insira o Logradouro'),
                cep: Yup.string().max(9).required('Por favor, insira o CEP'),
                bairro: Yup.string().max(255).required('Por favor, insira o Bairro'),
                cidade: Yup.string().max(255).required('Por favor, insira o Cidade'),
                estado: Yup.string().max(255).required('Por favor, insira o Estado'),
                regime: Yup.string().max(255).required('Por favor, insira o Regime tributário'),
                qtBancos: Yup.string().max(255).required('Por favor, informe a quantidade de bancos'),
                qtContas: Yup.string().max(255).required('Por favor, informe a quantidade de contas'),
                qtBoletos: Yup.string().max(255).required('Por favor, informe a quantidade de boletos'),
                qtNotas: Yup.string().max(255).required('Por favor, informe a quantidade de notas'),
                qtContratos: Yup.string().max(255).required('Por favor, insira a quantidade de contratos'),
                senha: Yup.string().min(6).max(12).required('Por favor, insira a senha de acesso'),
                email: Yup.string().email('Insira um email valido').min(8).max(178).required('Por favor, informe o email'),
                celular: Yup.string().min(8).max(12).required('Por favor, insira um número de celular'),
                cpf: Yup.string().min(8).required('Por favor, insira o cpf'),
                nascimento: Yup.string().min(6).max(12).required('Por favor, insira a data de nascimento'),

              })
            }
            onSubmit={(values, { resetForm }) => {
              //console.log('post acionado',values)
               gravaCadastro(values,resetForm)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3, margin:'60px 0px 30px 0px' }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Cadastro de empresa
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    
                  </Typography>
                </Box>
                { alertCadastro && (<Alert severity="warning">Atenção! Esse cnpj já está cadastrado.</Alert>)}
                {/*<Divider>Informações sobre a empresa</Divider>*/}
                <TextField
                  error={Boolean(touched.razaoSocial && errors.razaoSocial)}
                  fullWidth
                  helperText={touched.razaoSocial && errors.razaoSocial}
                  label="Razão social"
                  margin="normal"
                  name="razaoSocial"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.razaoSocial}
                  variant="outlined"
                  required
                />
                <TextField
                  error={Boolean(touched.cnpj && errors.cnpj)}
                  fullWidth
                  helperText={touched.cnpj && errors.cnpj}
                  label="CNPJ"
                  margin="normal"
                  name="cnpj"
                  onBlur={handleBlur,handleChange2}
                  onChange={handleChange}
                  value={values.cnpj}
                  variant="outlined"
                  required
                />
                <TextField
                  error={Boolean(touched.inscricaoMunicipal && errors.inscricaoMunicipal)}
                  fullWidth
                  helperText={touched.inscricaoMunicipal && errors.inscricaoMunicipal}
                  label="Inscrição municipal"
                  margin="normal"
                  name="inscricaoMunicipal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.inscricaoMunicipal}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.inscricaoEstatual && errors.inscricaoEstatual)}
                  fullWidth
                  helperText={touched.inscricaoEstatual && errors.inscricaoEstatual}
                  label="Inscrição estatual"
                  margin="normal"
                  name="inscricaoEstatual"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.inscricaoEstatual}
                  variant="outlined"
                />
<TextField
                  error={Boolean(touched.regime && errors.regime)}
                  helperText={touched.regime && errors.regime}
                  fullWidth
                  label="Regime tributário"
                  name="regime"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.regime}
                  variant="outlined"
                  margin="normal"
                >
                  {regimes.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  error={Boolean(touched.qtBancos && errors.qtBancos)}
                  helperText={touched.qtBancos && errors.qtBancos}
                  label="Quantidade de bancos"
                  margin="normal"
                  name="qtBancos"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.qtBancos}
                  variant="outlined"
                  required
                  sx={{
                      padding:'0px 30px 0px 0px'
                  }}
                />
                <TextField
                  error={Boolean(touched.qtContas && errors.qtContas)}
                  helperText={touched.qtContas && errors.qtContas}
                  label="Quantidade de contas"
                  margin="normal"
                  name="qtContas"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.qtContas}
                  variant="outlined"
                  required
                />
                <TextField
                  error={Boolean(touched.boletos && errors.boletos)}
                  helperText={touched.boletos && errors.boletos}
                  fullWidth
                  label="Emite boletos?"
                  name="boletos"
                  onChange={handleChange,selectEmiteBoleto}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={emiteBoletos}
                  variant="outlined"
                  margin="normal"
                >
                  {selSimNao.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                {mostraCampoBoletos && (<TextField
                  error={Boolean(touched.qtBoletos && errors.qtBoletos)}
                  helperText={touched.qtBoletos && errors.qtBoletos}
                  label="Quantidade de boletos"
                  margin="normal"
                  name="qtBoletos"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.qtBoletos}
                  variant="outlined"
                  required
                />)}
                <TextField
                  error={Boolean(touched.protesto && errors.protesto)}
                  helperText={touched.protesto && errors.protesto}
                  fullWidth
                  label="Realiza Protesto automatico?"
                  name="protesto"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={values.protesto}
                  variant="outlined"
                  margin="normal"
                >
                  {selSimNao.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label="Como é o processo de venda, da chegada do cliente a emissão do boleto atualmente"
                  multiline
                  rows={4}
                  variant="outlined"
                  margin="normal"
                  name="processoVenda"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.processoVenda}
                />
                <TextField
                  error={Boolean(touched.qtNotas && errors.qtNotas)}
                  helperText={touched.qtNotas && errors.qtNotas}
                  label="Média de Notas"
                  margin="normal"
                  name="qtNotas"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.qtNotas}
                  variant="outlined"
                  required
                  margin="normal"
                  sx={{
                      padding:'0px 30px 0px 0px'
                  }}
                />

                <TextField
                  error={Boolean(touched.qtContratos && errors.qtContratos)}
                  helperText={touched.qtContratos && errors.qtContratos}
                  label="Média de contratos mês"
                  margin="normal"
                  name="qtContratos"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.qtContratos}
                  variant="outlined"
                  required
                  margin="normal"
                />
                <TextField
                  error={Boolean(touched.temSistema && errors.temSistema)}
                  helperText={touched.temSistema && errors.temSistema}
                  fullWidth
                  label="Atualmente utiliza algum sistema para o financeiro?"
                  name="temSistema"
                  onChange={handleChange,selectTemSistema}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={temSistema}
                  variant="outlined"
                  margin="normal"
                >
                  {selSimNao.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                {mostraCampoSistema &&(<TextField
                  error={Boolean(touched.sistema && errors.sistema)}
                  helperText={touched.sistema && errors.sistema}
                  label="Qual o nome do sistema?"
                  margin="normal"
                  name="sistema"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.sistema}
                  variant="outlined"
                  required
                  margin="normal"
                  fullWidth
                />)}
                <Divider>Endereço da empresa</Divider>
                <TextField
                  error={Boolean(touched.cep && errors.cep)}
                  helperText={touched.cep && errors.cep}
                  label="Cep"
                  margin="normal"
                  name="cep"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.cep}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.logradouro && errors.logradouro)}
                  fullWidth
                  helperText={touched.logradouro && errors.logradouro}
                  label="Logradouro"
                  margin="normal"
                  name="logradouro"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.logradouro}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.bairro && errors.bairro)}
                  helperText={touched.bairro && errors.bairro}
                  
                  label="Bairro"
                  margin="normal"
                  name="bairro"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.bairro}
                  variant="outlined"
                  sx={{
                      padding:'0px 30px 0px 0px'
                  }}
                />  
                <TextField
                  error={Boolean(touched.cidade && errors.cidade)}
                  helperText={touched.cidade && errors.cidade}
                  
                  label="Cidade"
                  margin="normal"
                  name="cidade"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.cidade}
                  variant="outlined"

                />
                <TextField
                  error={Boolean(touched.estado && errors.estado)}
                  helperText={touched.estado && errors.estado}
                  label="Estado"
                  margin="normal"
                  name="estado"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.estado}
                  variant="outlined"
                  required
                />
                <Divider>Dados de acesso</Divider>
                <TextField
                  error={Boolean(touched.nome && errors.nome)}
                  helperText={touched.nome && errors.nome}
                  label="Nome completo"
                  margin="normal"
                  name="nome"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.nome}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  error={Boolean(touched.nascimento && errors.nascimento)}
                  helperText={touched.nascimento && errors.nascimento}
                  label="Data nascimento"
                  margin="normal"
                  name="nascimento"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.nascimento}
                  variant="outlined"
                  sx={{
                      padding:'0px 30px 0px 0px'
                  }}
                />
                <TextField
                  error={Boolean(touched.cpf && errors.cpf)}
                  helperText={touched.cpf && errors.cpf}
                  label="CPF"
                  margin="normal"
                  name="cpf"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.cpf}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.celular && errors.celular)}
                  helperText={touched.celular && errors.celular}
                  label="Celular"
                  margin="normal"
                  name="celular"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.celular}
                  variant="outlined"
                />
                 <TextField
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                  fullWidth
                  
                />
                <TextField
                  error={Boolean(touched.senha && errors.senha)}
                  helperText={touched.senha && errors.senha}
                  label="Senha"
                  margin="normal"
                  name="senha"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.senha}
                  variant="outlined"
                />
                
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Concluir cadastro
                  </Button>
                </Box>
                {alertVisivel && (<AlertDialog/>)}
                {/*<Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>*/}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Register;