import { useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';



const CnpjDetalhes = (props) => {
  const { data } = props;
  const titulo = `Empresa: ${data.Nome}`;
  console.log('detalhes do cnpj', data);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <form
      ref={componentRef}
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          subheader=""
          title={titulo}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CNPJ"
                name="state"
                value={data.CnpjCpf}
                variant="outlined"
              >
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome Fantasia"
                name="docName"
                value={data.NomeFantasia}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Logradouro"
                value={data?.Logradouro}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Número"
                value={data?.NumeroLogradouro}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Complemento"
                value={data?.ComplementoEndereco}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bairro"
                value={data?.BairroEndereco}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Cidade"
                value={data?.MunicipioEndereco}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CEP"
                value={data?.CepEnderecoFormatado}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Estado"
                value={data?.UfEndereco}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefone principal"
                value={data?.TelefonePrincipal}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email principal"
                value={data?.EmailPrincipal}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Site"
                value={data?.Site}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita situação"
                value={data?.ReceitaSituacao}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita data situação"
                value={data?.ReceitaDataSituacao!==null? moment(data?.ReceitaDataSituacao).format('DD/MM/YYYY') : ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita motivo situação"
                value={data?.ReceitaMotivoSituacao}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita tipo"
                value={data?.ReceitaTipo}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita data abertura"
                value={data?.ReceitaAbertura!==null? moment(data?.ReceitaAbertura).format('DD/MM/YYYY') : ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita natureza jurídica"
                value={data?.ReceitaNaturezaJuridica}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita EFR"
                value={data?.ReceitaEFR}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita situação especial"
                value={data?.ReceitaSituacaoEspecial}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita data situação especial"
                value={data?.ReceitaDataSituacaoEspecial!==null? moment(data?.ReceitaDataSituacaoEspecial).format('DD/MM/YYYY'):''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita capital social"
                value={data?.ReceitaCapitalSocial}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita cadastro raiz CNPJ"
                value={data?.CadastroRaizCNPJ_Id}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nire"
                value={data?.Nire}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Simples nacional"
                value={data?.SimplesNacional==false? 'Não':'Sim'}
                variant="outlined"
              />
            </Grid>

            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="SIMEI"
                value={data?.SIMEI==false? 'Não':'Sim'}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Simples nacional SIMEI"
                value={data?.SimplesNacionalSIMEIConsultaDe !==null? moment(data.SimplesNacionalSIMEIConsultaDe).format('DD/MM/YYYY'):''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Porte"
                value={data?.Porte}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Vadu atividade CNAE"
                value={data?.VaduAtividadeCNAE}
                variant="outlined"
              />
              </Grid>
              <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Receita matriz capital social"
                value={data?.ReceitaMatrizCapitalSocial}
                variant="outlined"
              />
              </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
            subheader="Receita atividade"
        />
        <CardContent>
            <div>{data?.ReceitaAtividade}</div>
        </CardContent>
        <Divider />
        <CardHeader
            subheader="Receita atividade secundária"
        />
        <CardContent>
            <div>{data?.ReceitaAtividadeSecundaria}</div>
        </CardContent>
        <Divider />
        { (data.Socios !== undefined && data.Socios.length > 0) && (
          <>
            <CardHeader
              subheader="Sócios"
            />
            <Divider />
            <CardContent>
              <TabelaSocios socios={data.Socios} />
            </CardContent>
          </>
        )}
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handlePrint}
          >
            Imprimir
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default CnpjDetalhes;
export const TabelaSocios = ({ socios }) => {
  console.log('socios', socios);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="right">Qualificação</TableCell>
            <TableCell align="right">Nome do representante legal</TableCell>
            <TableCell align="right">Qualificação do representante legal</TableCell>
            <TableCell align="right">País de origem</TableCell>  
          </TableRow>
        </TableHead>
        <TableBody>
          {socios.map((row) => (
            <TableRow
              hover
              key={row.Nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Nome}
              </TableCell>
              <TableCell align="right">{row.Qualificacao}</TableCell>
              <TableCell align="right">{row.NomeRepresentanteLegal}</TableCell>
              <TableCell align="right">{row.QualificacaoRepresentanteLegal}</TableCell>
              <TableCell align="right">{row.PaisOrigem}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
