import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import Budget from 'src/components/dashboard//Budget';
// import LatestOrders from 'src/components/dashboard/LatestOrders';
// import LatestProducts from 'src/components/dashboard/LatestProducts';
// import Sales from 'src/components/dashboard//Sales';
// import TasksProgress from 'src/components/dashboard/TasksProgress';
// import TotalCustomers from 'src/components/dashboard/TotalCustomers';
// import TotalProfit from 'src/components/dashboard/TotalProfit';
// import TrafficByDevice from 'src/components/dashboard/TrafficByDevice';
import Title from 'src/components/Title';

const Dashboard = () => (
  <>
    <Title />
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta BB 001" saldo="8000.00" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta BB 002" saldo="8500.00" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta BB 003" saldo="18000.00" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta Caixa 001" saldo="128340.00" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta Caixa 002" saldo="100.00" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta Caixa 004" saldo="7590.10" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta ITAÚ 001" saldo="18000.00" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta ITAÚ 002" saldo="2345.70" />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget name="Conta Santander 001" saldo="150000.00" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Dashboard;
