import { GoogleSpreadsheet } from "google-spreadsheet";

 const spreadsheet = '1Ji_PWPLnZhIdirICnt1_zKBi4axxscuJQNbimoWwxP8';
const clientEmail = 'demo-68@crypto-snow-331612.iam.gserviceaccount.com';
const privateKey = "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC/oCrsBZk3grPn\n64tJRYLsMbwNIlWXWSa6OflyvxO9Aqt4nYFRgFWM9UVy5GE8LcNAvGcKD1LnbTtd\nPWswybFEDfIuwsTQ1ur58y7kO3ZtvjY9UbEeTkaBE6L6iVlbH4JzxDAocQMD5fM6\nK6Y1pDuxheGiK3/NQX44brk0Kd0LAHcvWp8oHa7TIFKxI19MfSFo1Mmw33iMf2QX\nU/j/eEUDlSbC4avssQ8ejOx+G/gRhAspSTOMMs36xB2QBUOu8emqUWhQYMuF1FT5\nV9dcP/K0OU4U/fatODVifAyVe4g6N/DmFJ150auqQIGPJL/n3gGnbLnDatRoHa9g\nWOUhhNwxAgMBAAECggEADHeHa1kW5YdlnHxU0MTKtoKq1SDreCAG9r0wVzCKwHSE\nwuFeaTbD+hp39PhaQh/JEPRg8MTb9C4FW3TUPTWWCJMusy+dPegJnA7f+V3L/+pM\n3ZLrxsi5agrkws9+RoEni9ATAQKPO2jxuNJfqyQ+9Pb8ncAePbSZ2WKMvpHNrXoy\naHzxKNKkjxtr9rj/nyjJXLhx425zfP/aScE3fMsVjl8MbnWQMMot67qNmBo6JAYC\nZBRTT6wOWXRgGxPPurC40/9Vm511sRIo5PqXFkCOTlsclH7m2RZOTkJTeWVlao3D\nq9sb6kO/6wtwT2Fz0hleyxwgtNL4q4PS+3OOG7WPJQKBgQD/xYPrJ1eZpSEFt3au\ndfjKTWxNuQUiQ2s7FZxOaJzIC6iEAKm0EGAlKC78n0Fs/mZ7PBJY5LVTWXl3Rn51\n9P7PYq+kgJ0b2mR+InZja/bAC8hAytJqzPz6bDeGbAC56NbrfSs83e1D/AQZbz2y\nXnVfQTNtfFYFEIKdwm442Nt7lQKBgQC/y/wZj8IkYpRRfBYDGYc8Z0Q+MYB9jiaH\nzhjMWMVIMe7HcXqPSv6ZmlsJH5qKOBOlRBDPRtEo8Y2PyieLi8fumg75JWTVPQ9y\nBasoJXGWRXgJGG1cWqVUS5+EbCm/LPAwxGmQUbOcVX0nmznh5hnDDD32URjz/4LI\nSkrTPA7XLQKBgFT7PGSSctGzAvDST1WSDvKwnM6KLgF/Yw6DlKacrZLuBLSvP7N7\nSWavHkWDaIqdUFGb2QC+JN0numr9+iCZIVyojnFa5liNzJv4OVu5UIfookWc3iLs\nLxXJhP1dvzQF//rIYOhdWGshPf9xbY9pQRhAIqU7DZ2rKarfIh4zN2GtAoGBALIt\nO1c30QDiP61vXDem+JZpzOEGNbruWucg1LLJ6QSHtq67wkLqRZnG5rmUDYpITB/l\nGsSkolyqAnvJLnGZ+rOzlSNp1MGwhu/DSlzyZBJZGQlCEx6P6zVjR57KqQG8Kqrz\nXKpA7F3YoXsw3lX44IftkIaKkmbiQMn3EoooKhVFAoGAUyQwfiolLwaYVxn1pc8V\nRZ748YJKWyCFedtZYRTNvuHCSM1tNNXJLngdJeHBhph+Eixux+moaaA+kcmEKxDw\nwL6VIUxL0md6oLhfXVD9bFCY9bELPPHM5ZDtxWrvItqAhs05bu2IACtm/G3gooZ6\nOdh+m3f8A2keF0s3aFmzx50=\n-----END PRIVATE KEY-----\n";
 const sheetId = 0;



export default class GoogleSpreadsheetService {
    static client = () => {
        const doc = new GoogleSpreadsheet(spreadsheet);
        return doc;
    };

    static useServiceAccountAuth = async () => {
      try {
        const doc = new GoogleSpreadsheet(spreadsheet);
        await doc.useServiceAccountAuth({
            client_email: clientEmail,
            private_key: privateKey
        });

        await doc.loadInfo();
        const sheet = doc.sheetsById[0];
        //const rows = await sheet.getRows();
        //console.log(rows);
        return sheet;
      } catch (e) {
         console.error('Error: ', e);
      }
    }

    static getRows = async () => {
        const sheet = await this.useServiceAccountAuth();
        console.log('linhas',sheet.getRows());
        return sheet.getRows();
    }
  
    static convertToArray = (listRows) => {
        let aData = [] 
        for (let doc of listRows) {
          let obj = {}
          obj.Data = doc.Data;
          obj.Pagamento = doc.Pagamento;
          obj.Obs = doc.Obs;
          obj.Documento = doc.Documento || false;
          //console.log(doc);
          aData.push(obj)
        }
      return aData
    }

}
