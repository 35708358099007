import { useState, useRef } from 'react';
import { Box, Container } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import ConsultasForm from 'src/components/settings/ConsultasForm';
import CpfDetalhes from 'src/components/consultas/CpfDetalhes';
import CnpjDetalhes from 'src/components/consultas/CnpjDetalhes';
import Title from 'src/components/Title';

const ConsultasView = () => {
  const [doResulte, setDoResulte] = useState(false);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [tipoConsulta, setTipoConsulta] = useState(1);

  const validaConsulta = (data) => {
    console.log('data', data);
    setLoading(false)
    if (data !== false) {  
      setDoResulte(true);
      setResponse(data);
    } else {
      setDoResulte(false);
    }
  };
  return (
    <>
      <Title />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ pt: 3 }}>
            <ConsultasForm validaConsulta={validaConsulta} setLoading={setLoading} setDoResulte={setDoResulte} setTipoConsulta={setTipoConsulta} />
          </Box>
          {loading && (<Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>)}
          <Box sx={{ pt: 1 }}>
            {doResulte && tipoConsulta==1 && (<CpfDetalhes data={response} />)}
            {doResulte && tipoConsulta==2 && (<CnpjDetalhes data={response} />)}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ConsultasView;
