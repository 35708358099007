import { useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import Typography from '@mui/material/Typography';



const CpfDetalhes = (props) => {
  const { data } = props;
  console.log(data);
  const titulo = `Empresa: ${data.razaoSocial}`;
  console.log('detalhes do cpf', data);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const retornaRegime = (regime) => {
    if (regime == 1) {
       return 'Simples nacional';
    } else if(regime == 2){
       return 'MEI'
    } else if(regime==3){
       return 'Lucro presumido'
    } else {
       return 'Lucro real' 
    }
  }
  return (
    <form
      ref={componentRef}
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          subheader=""
          title= "Informações da empresa"
        />
       <Divider>Sobre a empresa</Divider>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Empresa:</strong> {data.razaoSocial}</div>

            </Grid>
            <Divider />
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>CNPJ:</strong> {data.cnpj}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
               <strong>Inscrição municipal:</strong> {data.inscricaoMunicipal}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
               <strong>Inscrição estatudal:</strong> {data.inscricaoEstatual}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Inscrição estatudal:</strong> {data.inscricaoEstatual}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
               <strong>Regime:</strong> {retornaRegime(data.regime)}
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Quantidade de bancos:</strong> {data.qtBancos}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Quantidade de contas:</strong> {data.qtContas}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
               <strong>Emite boletos:</strong> {data.emiteBoletos==0?'Não':'Sim'}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Quantidade de boletos:</strong> {data.qtBoletos}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Realiza Protesto automatico:</strong> {data.protesto==0?'Não':'Sim'}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Média de Notas:</strong> { data.qtNotas}
            </Grid>
            
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Média de contratos mês:</strong> { data.qtContratos}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Sistema para o financeiro:</strong> { data.temSistema==0?'Não':'Sim'}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <strong>Nome do sistema:</strong> { data.sistema}
            </Grid>
            
          </Grid>
          </CardContent>
        
          <>
            <CardHeader
              subheader="Processo de venda"
            />
            <CardContent>
             
               { data.processoVenda}
       
            </CardContent>
          </>
        <Divider>Endereço da empresa</Divider>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Logradouro:</strong> {data.logradouro}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Bairro:</strong> {data.bairro}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Cidade:</strong> {data.cidade}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Estado:</strong> {data.estado}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>CEP:</strong> {data.cep}</div>

            </Grid>
          </Grid>
        </CardContent>
         <Divider>Dados de acesso</Divider>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Nome:</strong> {data.nome}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Data nascimento:</strong> {data.nascimento}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>CPF:</strong> {data.cpf}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Celular:</strong> {data.celular}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Email:</strong> {data.email}</div>

            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
                <div><strong>Senha:</strong> {data.senha}</div>

            </Grid>
          </Grid>
        </CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handlePrint}
          >
            Imprimir
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default CpfDetalhes;
export const TabelaParentes = ({ parentes }) => {
  console.log('parentes', parentes);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="right">Parentesco</TableCell>
            <TableCell align="right">CPF</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parentes.map((row) => (
            <TableRow
              hover
              key={row.nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Nome}
              </TableCell>
              <TableCell align="right">{row.Parentesco}</TableCell>
              <TableCell align="right">{row.CPF}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const TabelaEnderecos = ({ enderecos }) => {
  console.log('enderecos', enderecos);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Logradouro</TableCell>
            <TableCell align="right">Número</TableCell>
            <TableCell align="right">Complemento</TableCell>
            <TableCell align="right">Bairro</TableCell>
            <TableCell align="right">Cidade</TableCell>
            <TableCell align="right">Uf</TableCell>
            <TableCell align="right">CEP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enderecos.map((row) => (
            <TableRow
              hover
              key={row.nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.logradouro}
              </TableCell>
              <TableCell align="right">{row.numero}</TableCell>
              <TableCell align="right">{row.complemento}</TableCell>
              <TableCell align="right">{row.bairro}</TableCell>
              <TableCell align="right">{row.cidade}</TableCell>
              <TableCell align="right">{row.uf}</TableCell>
              <TableCell align="right">{row.cep}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const TabelaEmails = ({ emails }) => {
  console.log('emails', emails);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails.map((row) => (
            <TableRow
              hover
              key={row.nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
