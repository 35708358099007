import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ConsultasView from 'src/pages/Consultas';
import Settings from 'src/pages/Settings';
import Exit from 'src/pages/Exit';
import BorderoList from 'src/pages/BorderoList';
import EmpresaList from 'src/pages/EmpresaList';
import BorderoNew from 'src/pages/BorderoNew';
import Register from 'src/pages/Register';
import Empresa from 'src/pages/Empresa';
import DocumentoList from './pages/DocumentoList';



const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'borderos', element: <BorderoList /> },
      { path: 'documentos', element: <DocumentoList /> },
      { path: 'empresas', element: <EmpresaList /> },
      { path: 'bordero-novo', element: <BorderoNew /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'consultas', element: <ConsultasView /> },
      { path: 'settings', element: <Settings /> },
      { path: 'exit', element: <Exit /> },
      { path: 'empresa/:empresa', element: <Empresa /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Login /> },
      { path: '/minha-empresa', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
