import React, { useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import BorderoListResults from 'src/components/bordero/BorderoListResults';
import BorderoToolbar from 'src/components/bordero/BorderoToolbar';
import customers from 'src/__mocks__/borderos';
import Title from 'src/components/Title';

const BorderoList = () => {
  useEffect(async () => {
    const user = await localStorage.getItem('@valecred/user');
    console.log('localStorage', user);
  }, []);
  return (
    <>
      <Title />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <BorderoToolbar />
          <Box sx={{ pt: 3 }}>
            <BorderoListResults customers={customers} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BorderoList;
