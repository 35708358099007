import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  Alert,
  Divider
} from '@material-ui/core';
import FirebaseService from '../services/FirestoreService';

const Login = () => {
  const [alertlVisible, setAlertVisible] = useState(false);
  const navigate = useNavigate();
  const doLogin = async (form) => {
    setAlertVisible(false);
    const response = await FirebaseService.getUsuarioByEmail(form.email, form.password, navigate);
    if (response === false) {
      setAlertVisible(true);
    }
  };
  
  const fnRegister = () => {
     navigate('/minha-empresa')
  }

  return (
    <>
      <Helmet>
        <title>Login | Finup</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              doLogin(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    align="center"
                  >
                    Gerenciamento de documentos
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    Login
                    { alertlVisible && (<Alert severity="warning">Credenciais inválidas</Alert>)}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Logar
                  </Button>
                </Box>
                <Divider sx={{ py: 2, marginTop: 7, marginBottom: 7 }}>
                  <Typography
                    color="textPrimary"
                    variant="h6"
                    align="center"
                  >
                    OU
                  </Typography>
                </Divider>
                <Box >
                  <Button
                    color="primary"
                    disabled={false}
                    fullWidth
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={fnRegister}
                  >
                    Cadastrar minha empresa
                  </Button>
                </Box>
                
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
