import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate, Link } from 'react-router-dom';
import FirebaseService from '../../services/FirestoreService';

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Button
} from '@material-ui/core';
// import getInitials from 'src/utils/getInitials';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';


const CustomerListResults = ({ customers, ...rest }) => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);




  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleViewCustomer = async (customer) => {
    //const cnpj = await FirebaseService.encriptPass(customer.cnpj);
    navigate('/app/empresa/'+customer.id);
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Data
                </TableCell>
                <TableCell>
                  Razão Social
                </TableCell>
                <TableCell>
                  CNPJ 
                </TableCell>
                <TableCell>
                  Inscrição municipal
                </TableCell>
                <TableCell>
                  Inscrição estatual
                </TableCell>

                <TableCell>
                  Status
                </TableCell>

                <TableCell align="center">
                  Baixar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.slice(0, limit).map((customer) => (
                <TableRow
                  hover
                  key={customer.id}
                  selected={false}
                >
                  <TableCell>
                    {moment(customer.createdAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {customer.razaoSocial}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {customer.cnpj}
                  </TableCell>
                  <TableCell>
                    {`${customer.inscricaoEstatual}`}
                  </TableCell>
                  <TableCell>
                    {`${customer.inscricaoMunicipal}`}
                  </TableCell>
                  <TableCell>
                    
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={()=>handleViewCustomer(customer)}
                    >
                      Visualizar
                    </Button>
                    {/*<Link to="/app/empresa/{customer}">Ver</Link>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array.isRequired
};

export default CustomerListResults;
