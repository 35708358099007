import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: 'AIzaSyDgrepXgOvCAC-wCwwtiL29-L6eDD6zWtA',
  authDomain: 'termostagesuperlogica.firebaseapp.com',
  projectId: 'termostagesuperlogica',
  storageBucket: 'termostagesuperlogica.appspot.com',
  messagingSenderId: '326835135378',
  appId: '1:326835135378:web:f79a9493e229bd95a6174b'
};
export const firebaseImpl = firebase.initializeApp(config);
export const firebaseDatabase = firebase.firestore();
