import React, { useEffect,useState } from 'react';
import { Box, Container } from '@material-ui/core';
import EmpresaListResults from 'src/components/empresa/EmpresaListResults';
import EmpresaToolbar from 'src/components/empresa/EmpresaToolbar';
import customers from 'src/__mocks__/borderos';
import Title from 'src/components/Title';
import FirebaseService from '../services/FirestoreService';


const EmpresaList = () => {
  let empresas = false;
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [listaEmpresas, setListaEmpresas] = useState({});
  useEffect(async () => {
    empresas = await FirebaseService.listaEmpresas();
    console.log('response aas', empresas);
    console.log('response test', customers);
    setListaEmpresas(empresas)
    
    setIsLoadingList(true)
  }, []);
  return (
    <>
      <Title />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <EmpresaToolbar />
          <Box sx={{ pt: 3 }}>
            {isLoadingList && (<EmpresaListResults customers={listaEmpresas} />)}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EmpresaList;
