import axios from 'axios';

 const baseURL = 'http://3.218.228.126';
 const key = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJWYWR1IiwidXNyIjoxNTYyLCJlbWwiOiJhbGV4YW5kcmVAc2ltdWxhY2FvLmNvbS5iciIsImVtcCI6MjQzNDYyNX0.d8PHKqDlB3ZdT3G6ixxSVr_mPLy9vFRXNYZbgF1RzDM';
 const endpointToken = 'http://localhost:3000/auth/vadu.dll/Autenticacao/JSONPegarToken';
 const vaduBaseURL = 'https://www.vadu.com.br';
 const endpointCPF = 'http://localhost:3000/vaduApi/ServicoAnaliseOperacao/ConsultaPF/';
 const endpointCNPJ = 'http://localhost:3000/vaduApi/vadu.dll/ServicoAnaliseOperacao/Consulta/';


//const key = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJWYWR1IiwidXNyIjoxNTYyLCJlbWwiOiJhbGV4YW5kcmVAc2ltdWxhY2FvLmNvbS5iciIsImVtcCI6MjQzNDYyNX0.d8PHKqDlB3ZdT3G6ixxSVr_mPLy9vFRXNYZbgF1RzDM';
//const endpointToken = 'https://cors-anywhere.herokuapp.com/http://3.218.228.126/vadu.dll/Autenticacao/JSONPegarToken';
//const endpointCPF = 'https://cors-anywhere.herokuapp.com/https://www.vadu.com.br/vaduIntegracao.dll/ServicoAnaliseOperacao/ConsultaPF/';
//const endpointCNPJ = 'https://cors-anywhere.herokuapp.com/https://www.vadu.com.br/vadu.dll/ServicoAnaliseOperacao/Consulta/';
//const baseURL = 'http://3.218.228.126';
//const vaduBaseURL = 'https://www.vadu.com.br';
export default class VaduService {
    static client = (token = false) => {
      let validToken = token;
      if (validToken === false) {
        validToken = key;
      }
      const api = axios.create({
        baseURL,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      });
      api.defaults.timeout = 18000;
      api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      api.defaults.headers.common = { Pragma: 'no-cache' };
      api.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
      return api;
    };
    static clientVadu = () => {
      const api = axios.create({
        baseURL: vaduBaseURL,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'valecred-elemento-app.s3-website-us-east-1.amazonaws.com'
        },
      });
      api.defaults.timeout = 18000;
      api.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://valecred-elemento-app.s3-website-us-east-1.amazonaws.com';
      api.defaults.headers.common = { Pragma: 'no-cache' };
      api.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
      return api;
    };

    static authenticate = async () => {
      const configs = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${key}`,
        }
      };
      console.log('authenticate', configs);
      const token = await this.client().get(endpointToken, configs);
      return token.data.token;
    }

    static consultaCPF = async (cpf) => {
      console.log('consultaCPF');
      const token = await this.authenticate();
      console.log('consultaCPF Token', token);
      try {
        const configs = { headers: { Authorization: `Bearer ${token}`, } };
        const data = await this.client().get(endpointCPF + cpf, configs);
        console.log(data);
        return data;
      } catch (ex) {
        console.log('error', ex);
        throw ex;
      }
    }

    static consultaCNPJ = async (cnpj,serasa=0, receita=0) => {
      const token = await this.authenticate();
      console.log('consultaCNPJ Token', token);
      const url = endpointCNPJ + cnpj +'?UltimoSerasa='+serasa+'&AtualizaCadastro='+ receita;
      try {
        const configs = { headers: { Authorization: `Bearer ${token}`, } };
        const data = await this.client().get(url, configs);
        console.log(data);
        return data;
      } catch (ex) {
        console.log('error', ex);
        throw ex;
      }
    }
}
