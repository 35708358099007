import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    cedente: 'Super Contrutora ',
    cedente_cnpj: '81.417.978/0001-55',
    empresa_cnpj: '07.441.158/0001-46',
    grupo: 'GEO  E NOTA',
    status: 'em análise'
  },
  {
    id: uuid(),
    cedente: 'Magna Informatica',
    cedente_cnpj: '40.589.932/0001-36',
    empresa_cnpj: '58.342.121/0001-00',
    grupo: 'GEO  E NOTA',
    status: 'em análise'
  },
  {
    id: uuid(),
    cedente: 'Super Contrutora ',
    cedente_cnpj: '27.116.677/0001-46',
    empresa_cnpj: '07.441.158/0001-46',
    grupo: 'GEO  E NOTA',
    status: 'em análise'
  },
];
