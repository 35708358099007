import { useState, useRef,useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import Title from 'src/components/Title';
import { useParams } from 'react-router-dom';
import GoogleSpreadsheetService from '../services/GoogleSpreadsheetService';
import DocumentoToolbar from 'src/components/documento/DocumentoToolbar';
import DocumentoListResults from 'src/components/documento/DocumentoListResults';
import Progress from 'src/components/Progress';


const DocumentoList = () => {
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [response, setResponse] = useState({});
  const [filterEmail, setFilterEmail] = useState(false);
  
  const [userAdmin, setUserAdmin] = useState(true);
  
  useEffect(async () => {
    const listaLancamentos = await GoogleSpreadsheetService.getRows();
    const user = await localStorage.getItem('@valecred/user');
    console.log('user.is', JSON.parse(user).admin);
    if (!JSON.parse(user).admin) {
      handleChangeEmail(JSON.parse(user).email);
      setUserAdmin(false);
    }
 
    setResponse(listaLancamentos)
    setIsLoadingList(true)
  }, []);
  
  const handleChangeEmail= (email) => {
    setFilterEmail(email)
  };
  

  return (
    <>
      <Title />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          {userAdmin && (<DocumentoToolbar fn={handleChangeEmail} />)}
          
          <Box sx={{ pt: 1 }}>
            
            {isLoadingList ===false? (<Progress/>) : (<DocumentoListResults documents={response} filterEmail={filterEmail} />)}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DocumentoList;
