import {
  Box,
  Container,
  Grid
} from '@material-ui/core';
import BorderoNewForm from 'src/components/bordero/BorderoNewForm';
import Title from 'src/components/Title';

const BorderoNew = () => (
  <>
    <Title />
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            <BorderoNewForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default BorderoNew;
