import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

const Exit = () => {
  const navigate = useNavigate();
  useEffect(async () => {
    reactLocalStorage.clear();
    navigate('/', { replace: true });
  }, []);
  return (
    <>
    </>
  );
};
export default Exit;
