import CryptoJS from 'crypto-js';
import { reactLocalStorage } from 'reactjs-localstorage';
import { firebaseDatabase } from '../utils/firebaseConfig';

export default class FirebaseService {
    static listaUsuarios = async () => {
      const response = await firebaseDatabase.collection('usuarios').get();
      response.docs.map((item) => {
        console.log('item', item.data());
        return item;
      });
      return response;
    }

    static getUsuarioByEmail = async (email, password, navigate) => {
      await firebaseDatabase.collection('usuarios')
        .where('email', '==', email)
        .get().then(async (querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            const pass = await this.decriptPass(doc.data().password);
            if (pass === password) {
              console.log('doc', doc.data());
              reactLocalStorage.setObject('@valecred/user', doc.data());
              navigate('/app/documentos', { replace: true });
            }
            return false;
          });
        })
        .catch((error) => {
          console.log('erro login nao localizado', error);
        });
      return false;
    }
  
  static getEmpresaByCnpj = async (cnpj) => {
    let response = false;
    await firebaseDatabase.collection("empresas")
        .where("cnpj", "==", cnpj)
        .get()
        .then(snap => {
            snap.forEach(doc => {
              console.log("retorno",doc.data());
              response = doc.data()
            });
        });
    return response
  }
  
  static getEmpresaByID = async (id) => {
    let response = false;
    await firebaseDatabase.collection("empresas")
        .where("id", "==", id)
        .get()
        .then(snap => {
            snap.forEach(doc => {
              console.log("retorno",doc.data());
              response = doc.data()
            });
        });
    return response
  }

  static getUsuarioByEmail2 = async (email) => {
      const response = await firebaseDatabase.collection('usuarios')
        .where('email', '==', email)
        .get();
      console.log('response', response);
      return response;
  }

  static decriptPass = async (password) => {
      const key = CryptoJS.enc.Utf8.parse('bb5524255a7f54d2726a951bb39204dd');
      const decryptedWA = CryptoJS.AES.decrypt(password, key, { iv: CryptoJS.enc.Utf8.parse('1583288699248111') });
      console.log('bbb:', decryptedWA.toString(CryptoJS.enc.Utf8));
      return decryptedWA.toString(CryptoJS.enc.Utf8);
  }

  static encriptPass = async (password) => {
      const key = CryptoJS.enc.Utf8.parse('bb5524255a7f54d2726a951bb39204dd');
      const encryptedCP = CryptoJS.AES.encrypt(password, key, { iv: CryptoJS.enc.Utf8.parse('1583288699248111') });
      const cryptText = encryptedCP.toString();
      return cryptText;
  }
  
  static addEmpresa = async (empresa) => {
        return await firebaseDatabase.collection('empresas').add(empresa)
  }
  
  static listaEmpresas = async () => {
    console.log('Liata de empresas');
    const response = await firebaseDatabase.collection('empresas').get();
    return response.docs.map(doc => doc.data());

  }
  

}

// collection: usuarios
// email
// "rogepage5@gmail.com"
// nome
// "Rogerio Lima"
// password
// "E5nzRnlJk67eL4zoEnLtIg=="
// telefone
// "15981134009"
