import { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import {
  Card,
  CardHeader,
  Divider,
  Grid,
  TextField,
  CardContent,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Select,
  MenuItem
} from '@material-ui/core';
import VaduService from '../../services/VaduService';

const ConsultasForm = ({ validaConsulta, setLoading, setDoResulte, setTipoConsulta}) => {
  console.log(validaConsulta);
  const [Value, setValue] = useState('1');
  const [docNumber, setDocNumber] = useState();
  const doConsulta = async (form) => {
    setDoResulte(false);
    setLoading(true);
    let response
    try {
      setTipoConsulta(Value)
      if(Value==1){
        response = await VaduService.consultaCPF(form.documento);
      }else{
        response = await VaduService.consultaCNPJ(form.documento);
      }
      
      validaConsulta(response.data);
    } catch (ex) {
      validaConsulta(false);
    }
    return true;
  };
  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      console.log('do validate')
    }
  }

  const handleChange = (event) => {
    console.log('aaa=>', event.target.value);
    setValue(event.target.value);
  };

  return (
    <Card>
      <CardHeader subheader="" title="Consulta" />
      <Divider />
      <CardContent>
        <Formik
          initialValues={{ documento: '', serasa: false, receita: false }}
          validationSchema={Yup.object().shape({
            documento: Yup.string().max(16).required('informe o numero do documento a ser consultado')
          })}
          onSubmit={(values) => {
            doConsulta(values);
          }}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={3}
                  xs={6}
                >
                  <Select
                    labelId="Input label"
                    id="Select"
                    value={Value}
                    defaultValue={Value}
                    onChange={handleValueChange}
                  >
                    <MenuItem value="1">CPF</MenuItem>
                    <MenuItem value="2">CNPJ</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.documento && errors.documento)}
                    fullWidth
                    label="Numero documento"
                    name="documento"
                    onChange={handleChange}
                    required
                    value={values.documento}
                    variant="outlined"
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                {/* <Grid
                  item
                  md={3}
                  xs={6}
                >
                  <Field type="checkbox" name="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                    <label htmlFor="acceptTerms" className="form-check-label">Último Serasa</label>
                  <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={6}
                >
                 <Field type="checkbox" name="acceptTerms" className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')} />
                    <label htmlFor="acceptTerms" className="form-check-label">Atualiza Cadastro</label>
                  <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                </Grid> */}
              </Grid> 
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  color="primary"
                  disabled={false}
                  type="submit"
                  variant="contained"
                >
                  Consultar
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default ConsultasForm;
