
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Container } from '@material-ui/core';
const Progress = () => (
  <Box sx={{  display: 'flex',
            justifyContent: 'center' }}>
     <CircularProgress color="inherit" />
  </Box>
);

export default Progress;
