import { useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';



const CpfDetalhes = (props) => {
  const { data } = props;
  const titulo = `Cliente: ${data.nome}`;
  console.log('detalhes do cpf', data);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <form
      ref={componentRef}
      autoComplete="off"
      noValidate
      {...props}
    >
      <Card>
        <CardHeader
          subheader=""
          title={titulo}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="CPF"
                name="state"
                value={data.cpf}
                variant="outlined"
              >
                <div>{data.nome}</div>
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Data nascimento"
                name="docName"
                value={moment(data.dataDeNascimento).format('DD/MM/YYYY')}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Nome da mãe"
                value={data.nomeDaMae}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Conjugue"
                name="email"
                value={data.conjuge?.nome}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Logradouro"
                value={data?.endereco?.logradouro}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Número"
                value={data?.endereco?.numero}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Complemento"
                value={data?.endereco?.complemento}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Bairro"
                value={data?.endereco?.bairro}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Cidade"
                value={data?.endereco?.cidade}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="Estado"
                value={data?.endereco?.uf}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <TextField
                fullWidth
                label="CEP"
                value={data?.endereco?.cep}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        { (data.parentes !== undefined && data.parentes.length > 0) && (
          <>
            <CardHeader
              subheader="Parentes"
            />
            <Divider />
            <CardContent>
              <TabelaParentes parentes={data.parentes} />
            </CardContent>
          </>
        )}
        <Divider />
        { (data.enderecos !== undefined && data.enderecos.length > 0) && (
          <>
            <CardHeader
              subheader="Outros endereços"
            />
            <Divider />
            <CardContent>
              <TabelaEnderecos enderecos={data.enderecos} />
            </CardContent>
          </>
        )}
        <Divider />
        { (data.emails !== undefined && data.emails.length > 0) && (
          <>
            <CardHeader
              subheader="Emails"
            />
            <Divider />
            <CardContent>
              <TabelaEmails emails={data.emails} />
            </CardContent>
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handlePrint}
          >
            Imprimir
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default CpfDetalhes;
export const TabelaParentes = ({ parentes }) => {
  console.log('parentes', parentes);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell align="right">Parentesco</TableCell>
            <TableCell align="right">CPF</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parentes.map((row) => (
            <TableRow
              hover
              key={row.nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Nome}
              </TableCell>
              <TableCell align="right">{row.Parentesco}</TableCell>
              <TableCell align="right">{row.CPF}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const TabelaEnderecos = ({ enderecos }) => {
  console.log('enderecos', enderecos);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Logradouro</TableCell>
            <TableCell align="right">Número</TableCell>
            <TableCell align="right">Complemento</TableCell>
            <TableCell align="right">Bairro</TableCell>
            <TableCell align="right">Cidade</TableCell>
            <TableCell align="right">Uf</TableCell>
            <TableCell align="right">CEP</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enderecos.map((row) => (
            <TableRow
              hover
              key={row.nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.logradouro}
              </TableCell>
              <TableCell align="right">{row.numero}</TableCell>
              <TableCell align="right">{row.complemento}</TableCell>
              <TableCell align="right">{row.bairro}</TableCell>
              <TableCell align="right">{row.cidade}</TableCell>
              <TableCell align="right">{row.uf}</TableCell>
              <TableCell align="right">{row.cep}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const TabelaEmails = ({ emails }) => {
  console.log('emails', emails);
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails.map((row) => (
            <TableRow
              hover
              key={row.nome}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
