import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
} from '@material-ui/core';
import {
  LogOut as logOutIcon,
  BarChart as BarChartIcon,
  // Lock as LockIcon,
  Settings as SettingsIcon,
  ThumbsUp as ThumbsUpIcon,
  User as UserIcon,
  File as FileIcon,
  // AlertCircle as AlertCircleIcon,

} from 'react-feather';
import NavItem from './NavItem';

const items = [
  //{
  //  href: '/app/dashboard',
  //  icon: BarChartIcon,
  //  title: 'Dashboard'
  //},
  //{
  //  href: '/app/borderos',
  //  icon: FileIcon,
  //  title: 'Borderôs'
  //},
  
  {
    href: '/app/documentos',
    icon: FileIcon,
    title: 'Documentos'
  },
  {
    href: '/app/empresas',
    icon: UserIcon,
    title: 'Empresas'
  },
  //{
  //  href: '/app/consultas',
  //  icon: ThumbsUpIcon,
  //  title: 'Consultas CPF/CNPJ'
  //},
  //{
  //  href: '/app/account',
  //  icon: UserIcon,
  //  title: 'Minha conta'
  //},
  //{
  //  href: '/app/settings',
  //  icon: SettingsIcon,
  //  title: 'Settings'
  //},
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: '404'
  // },
  {
    href: '/app/exit',
    icon: logOutIcon,
    title: 'Sair'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
